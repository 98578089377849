import API_IDIVIA from './config';
import router from '../router';

async function getToken() {
  return localStorage.getItem('token');
}

async function cleanOldLogin() {
  await localStorage.removeItem('token');
  await localStorage.removeItem('user');
  router.push('/login');
}

const idiviaService = {
  /* +++++++++++++++++++++++++PRODUTOS++++++++++++++++++++++++++++++ */
  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - OBTEMOS A LISTA DE PRODUTOS */
  async getProducts() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async getThreshold(company) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}settings/getThreshold`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: company,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async updateThreshold(company, threshold) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}settings/updateThreshold`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: company,
        threshold: threshold,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async modifyOrder(order_id, new_articles, total) {
    const token = await getToken();
    console.log(order_id, new_articles, total);
    const response = await fetch(`${API_IDIVIA}orders/modifyOrder`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: order_id,
        articles: new_articles,
        total: total,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getLoggedUserInfo() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}users/getLoggedUserInfo`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async insertPendingSubject(subject, description) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}pendingSubjects/createTicket`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject: subject,
        description: description,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async insertPendingSubjectsMsg(ticket_id, msg) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}pendingSubjects/insertMessage`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ticket_id: ticket_id,
        message: msg,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getPendingSubjects() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}pendingSubjects/getTickets`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersFull(startDate, endDate) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}salesReportCountry/getOrders`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        start_date: startDate,
        end_date: endDate,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async createAsneiraTicket(id_accused, mistake, description, company_cost) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}asneiras/tickets/create`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_accused: id_accused,
        mistake: mistake,
        description: description,
        company_cost: company_cost,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getUserAndId() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}users/getUsernameAndId`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAsneiras() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}asneiras/tickets/listAll`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async decideAsneiraTicket(
    ticket_id,
    final_decider_id,
    percentage_guilt,
    justification,
    company_cost
  ) {
    console.log(
      'idivia service: ',
      ticket_id,
      final_decider_id,
      percentage_guilt,
      justification
    );
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}asneiras/tickets/decide`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mistake_id: ticket_id,
        final_decider_id: final_decider_id,
        percentage_guilt: percentage_guilt,
        justification: justification,
        company_cost: company_cost,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cancelAsneiraTicket(ticket_id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}asneiras/tickets/cancelTicket`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ticket_id: ticket_id,
      }),
    });

    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getProductID(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/${id}/details`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getProductsPurchase() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/purchase`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - OBTEMOS A LISTA DE PRODUTOS */
  async getEAN() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/ean`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getProductBySKU(sku) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/${sku}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - EDITAR PRODUTO */
  async editProduct(
    id,
    ean,
    sku,
    sku_old,
    warehouse,
    finish,
    weight,
    hscode,
    country,
    new1,
    validity,
    iva
  ) {
    console.log(
      id,
      ean,
      sku,
      sku_old,
      warehouse,
      finish,
      weight,
      hscode,
      country,
      new1,
      validity
    );
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/${id}/update`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ean: ean,
        sku: sku,
        sku_old: sku_old,
        warehouse: warehouse,
        finish: finish,
        weight: weight,
        hscode: hscode,
        country: country,
        new: new1,
        validity: validity,
        iva: iva,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async searchProducts(sku, name, ean, brand) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/filter`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        name: name,
        ean: ean,
        brand: brand,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async searchProductsEanSku(search) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/search`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        search: search,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async fixStock(id, qty, reason, comment) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/stock/fix`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        qty: qty,
        reason: reason,
        comment: comment,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async getProdTrans(id, start_date, final_date) {
    const token = await getToken();
    console.log(id, start_date, final_date);
    const response = await fetch(`${API_IDIVIA}productTrans/get`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        start_date: start_date,
        final_date: final_date,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async getProdExtraInfo(id) {
    const token = await getToken();
    console.log(id);
    const response = await fetch(`${API_IDIVIA}products/${id}/stockSite`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async updateProdExtraInfo(id, idivia_manual_price, idivia_auto_price) {
    const token = await getToken();
    console.log(id, idivia_manual_price, idivia_auto_price);
    const response = await fetch(`${API_IDIVIA}products/${id}/stockSite`, {
      method: 'PATCH',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        idivia_manual_price: idivia_manual_price,
        idivia_auto_price: idivia_auto_price,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async editProductName(id, name) {
    const token = await getToken();
    console.log(id, name);
    const response = await fetch(`${API_IDIVIA}products/name`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  //LOGS
  async searchDashboard() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}logs/getDashboard`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  async searchLogs(data, user, object_id, type) {
    console.log(data, user, object_id);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}logs/get`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        date: data,
        user: user,
        object_id: object_id,
        type: type,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },
  /* ++++++++++++++++++ ENCOMENDAS ++++++++++++++++++ */
  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - OBTEMOS A LISTA DE ENCOMENDAS  */
  async getOrders() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}search/getProcessing`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersAllProcessing() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}search/getAllProcessing`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getCodigoOrder(orderID) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/codes`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: orderID,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAmostras(orderID) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/amostras`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order: orderID,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getTaxs(country) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/taxacao`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        country: country,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - OBTEMOS A LISTA DE ENCOMENDAS  */
  async getOrdersProcessing() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/processing`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* FUNÇÃO PARA FILTRAR AS ENCOMENDAS */
  async getOrdersFilter(id, name, email, tracking) {
    console.log('FILTROOOOO');
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}search/search`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        email: email,
        tracking: tracking,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },

  async getOrdersFilterJunt(email) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}search/searchJunt`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      console.log(message);
      throw Error(message);
    }
  },

  /* FUNÇÃO QUE FAZ A LIGAÇÃO À API - OBTER ENCOMENDA POR ID */
  async getOrderId(id) {
    const token = await getToken();
    console.log('id', id);
    const response = await fetch(`${API_IDIVIA}orders/${id}/processing`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async createNewOrder(
    id,
    articles,
    address1,
    city,
    state,
    postCode,
    country,
    shippingLines,
    phone,
    email,
    firstName,
    lastName,
    status,
    total,
    currency,
    paymentMethods,
    feeLines,
    couponLines,
    shippingCompany,
    othersChoice,
    passTax,
    justifyReason
  ) {
    console.log(
      id,
      articles,
      address1,
      city,
      state,
      postCode,
      country,
      shippingLines,
      phone,
      email,
      firstName,
      lastName,
      status,
      total,
      currency,
      paymentMethods,
      feeLines,
      couponLines,
      shippingCompany,
      justifyReason
    );
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/new`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        articles: articles,
        address1: address1,
        city: city,
        state: state,
        postCode: postCode,
        country: country,
        shippingLines: shippingLines,
        phone: phone,
        email: email,
        firstName: firstName,
        lastName: lastName,
        status: status,
        currency: currency,
        total: total,
        paymentMethods: paymentMethods,
        feeLines: feeLines,
        couponLines: couponLines,
        shippingCompany: shippingCompany,
        othersChoice: othersChoice,
        passTax: passTax,
        justifyReason: justifyReason,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.detail;
      console.log(message);
      throw Error(message);
    }
  },

  async updateArticlesFromOrder(id, articles, total) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/${id}/update/articles`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        articles: articles,
        total: total,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  async updateStatusOrder(id, orderData, status, notes, creditNote) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/update/status`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        orderData: orderData,
        status: status,
        notes: notes,
        creditNote: creditNote,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  async updateDataFromDB(id) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}wordpress/product/update/${id}`,
      {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  async getProcessingOrders() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}wordpress/processing`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  async updateAddress(
    id,
    firstName,
    lastName,
    address,
    city,
    postalCode,
    state,
    country,
    notes
  ) {
    console.log(id, address, city, postalCode, country);
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}orders/processing/wrongaddress`,
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          firstName: firstName,
          lastName: lastName,
          address: address,
          city: city,
          CP: postalCode,
          state: state,
          country: country,
          notes: notes,
        }),
      }
    );
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addNotes(id, notes) {
    const token = await getToken();
    console.log(id, notes);

    const response = await fetch(`${API_IDIVIA}orders/addNote`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        notes: notes,
      }),
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  //Get previous Order Info
  async getPreviousOrderInfo(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/orderInfo`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async updateContact(id, contact, email) {
    const token = await getToken();
    console.log(id, contact);
    const response = await fetch(`${API_IDIVIA}orders/updateContact`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        contact: contact,
        email: email,
      }),
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateCancelled(id) {
    const token = await getToken();
    console.log(id);
    const response = await fetch(`${API_IDIVIA}orders/orderCancel`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async returnedOrder(id, articles, comment, validity) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/return`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        articles: articles,
        comment: comment,
        validity: validity,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async returnedProducts(idOrder) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/returnedProducts`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        idOrder: idOrder,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async backOrder(id, comment, invoice) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/back`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        invoice: invoice,
        comment: comment,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* ++++++++++++++++++++++++++ PRODUCTS REGISTER ++++++++++++++++++++++++++++++ */

  async getOrderProducts() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/orderProducts`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async addOrderProducts(id, products, picking) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/products/add`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        products: products,
        picking: picking,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  async updateOrdersProduct(id, products, picking, orderId, sku, ean) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/products/edit/${id}`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        products: products,
        picking: picking,
        orderId: orderId,
        sku: sku,
        ean: ean,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  /* +++++++++++++++++++++++++ MANUAL ORDERS ++++++++++++++++++++++++++++ */
  async createNewManualOrder(
    orderProducts,
    firstName,
    secondName,
    email,
    finalAdress,
    city,
    country,
    postCode,
    phone,
    state,
    shippingCompany,
    exchange,
    shippingLines,
    total,
    feeLine,
    site
  ) {
    console.log(exchange);
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/create/manual`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orderProducts: orderProducts,
        firstName: firstName,
        secondName: secondName,
        email: email,
        finalAdress: finalAdress,
        city: city,
        country: country,
        postCode: postCode,
        phone: phone,
        state: state,
        shippingCompany: shippingCompany,
        exchange: exchange,
        shippingLines: shippingLines,
        total: total,
        feeLine: feeLine,
        site: site,
      }),
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },

  /* ++++++++++++++++++++++++++ SHIPPING COMPANIES ++++++++++++++++++++ */
  async getShippingCompany() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/shippingCompany`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      throw Error(response.json());
    }
  },
  async getOrderPicking() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/all/picking`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrderExpedition() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/expedition`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async getOrderExpeditionById(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/expedition/${id}`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* +++++++++++++++++++++++++ USERS ++++++++++++++++++++++ */
  async login(email, password) {
    const response = await fetch(`${API_IDIVIA}users/login`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    /* console.log(await response.json()) */
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async register(email, username, password, confPassword) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}users/register`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
      body: JSON.stringify({
        email: email,
        username: username,
        password: password,
        confirmPassword: confPassword,
      }),
    });
    console.log(response);
    if (response.status == 201) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async getAllUsers() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}users/`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },

  async updateAdminType(email, bool) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}users/update/admin`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: email,
        bool: bool,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },

  async updateBlocked(email, bool) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}users/update/block`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: email,
        bool: bool,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },

  async updatePassword(token, oldPassword, newPassword, newPassword2) {
    const response = await fetch(`${API_IDIVIA}users/updatePassword`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        passwordCurrent: oldPassword,
        password: newPassword,
        passwordConfirm: newPassword2,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },

  /* ++++++++++++++++--COMMUNITARY COUNTRIES++++++++++++++++---- */
  async getCountry() {
    const response = await fetch(`${API_IDIVIA}countrys/communitary`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAllCountries() {
    const response = await fetch(`${API_IDIVIA}countrys/all`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateTrackingCountry(
    country,
    tracking_ini,
    tracking_final,
    tracking_single,
    tax_iva,
    tax_iva_reduced
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/tracking`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        country: country,
        tracking_ini: tracking_ini,
        tracking_final: tracking_final,
        tracking_single: tracking_single,
        tax_iva: tax_iva,
        tax_iva_reduced: tax_iva_reduced,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  /* async convertToEUR(from, amount) {
              const response = await fetch(`https://api.fastforex.io/convert?from=${from}&to=EUR&amount=${amount}&api_key=cb2df9483a-457b1d4e04-r5anys`, {
                  method: "GET",

                  headers: {
                      "Content-type": "application/json"
                  },
              })
              if (response.status === 200) {
                  return response.json()
              }
          }, */

  /* +++++++++++++++++ SUPPLIERS/RECEPTION ++++++++++++++++ */

  async addInvoicesSuppliers(
    invoiceId,
    products,
    date,
    supplier,
    status,
    total,
    totalWithoutIVA,
    discount,
    extraValue,
    valueInvoice,
    justification,
    withIva,
    validity_justification
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/invoices/add`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        bill_id: invoiceId,
        id_supp: supplier,
        status: status,
        transactions: products,
        date: date,
        total: total,
        totalWithoutIVA: totalWithoutIVA,
        discount: discount,
        extra_value: extraValue,
        invoiceValue: valueInvoice,
        justification: justification,
        with_iva: withIva,
        validity_justification: validity_justification,
      }),
    });
    console.log(response.data);
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async updateInvoiceSuppliers(
    invoiceId,
    products,
    date,
    supplier,
    status,
    total,
    id,
    totalWithoutIVA,
    discount,
    extraValue,
    valueInvoice,
    justification,
    withIva,
    creditNote,
    validity_justification
  ) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}suppliers/invoices/${id}/update`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bill_id: invoiceId,
          id_supp: supplier,
          transactions: products,
          date: date,
          status: status,
          total: total,
          totalWithoutIVA: totalWithoutIVA,
          discount: discount,
          extra_value: extraValue,
          invoiceValue: valueInvoice,
          justification: justification,
          with_iva: withIva,
          creditNote: creditNote,
          validity_justification: validity_justification,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getProductsPCustoInfo(prods) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}suppliers/invoices/product_info`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          prods: prods,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getAllInvoiceSuppliers() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/invoices`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getInvoiceSuppliersByID(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/invoices/${id}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getAllBrands() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}brands/`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async updateBrand(id, price_options, discount) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}brands/`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        option: price_options,
        discount: discount,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getSuppliers() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/all`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async getAllDataSuppliers() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/allData`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async addSuppliers(name, other_name) {
    console.log(name, other_name);
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/add`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        otherName: other_name,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async addAllDataSuppliers(
    name,
    other_name,
    email,
    contact,
    nif,
    account,
    password,
    url
  ) {
    console.log(name, other_name, email, contact, nif);
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/allData`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        otherName: other_name,
        email: email,
        contact: contact,
        nif: nif,
        account: account,
        password: password,
        url: url,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async updateDataSuppliers(
    id,
    name,
    other_name,
    email,
    contact,
    nif,
    account,
    password,
    url
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/update`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        otherName: other_name,
        email: email,
        contact: contact,
        nif: nif,
        account: account,
        password: password,
        url: url,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async updateDataSuppliersInvoiceType(id, type) {
    const token = await getToken();

    console.log(`${API_IDIVIA}suppliers/updateInvoice`);
    const response = await fetch(`${API_IDIVIA}suppliers/updateInvoice`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        type: type,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async removeReception(id) {
    console.log('ENTORU NO idiviaService');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}suppliers/delete`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async searchReception(
    invoiceID,
    supplier,
    status,
    start_date,
    final_date,
    noInvoice,
    creditNoteStatus
  ) {
    console.log('ENTORU NO idiviaService pesquisa');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}suppliers/search`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        invoiceID: invoiceID,
        supplier: supplier,
        status: status,
        start_date: start_date,
        final_date: final_date,
        no_invoice: noInvoice,
        creditNoteStatus: creditNoteStatus,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async addImageProductsReceived(id, image) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}suppliers/invoices/products/images`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          img: image,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async addFileReceptions(id, file, invoice_id) {
    const token = await getToken();
    let formData = new FormData();
    console.log(file);
    formData.append('file', file);
    formData.append('id', id);
    formData.append('invoice_id', invoice_id);
    const response = await fetch(`${API_IDIVIA}suppliers/invoices/file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async supplierByID(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}suppliers/${id}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  /* ++++++++++++++++++++ NACEX ++++++++++++++++++++ */
  async getExpeditionData(expedition) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}nacex/expedition/${expedition}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.json();
  },
  async nacexCreateExpedition(data, orderId) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}nacex/expedition`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: data,
        orderId: orderId,
      }),
    });
    console.log(response.data);
    return response.json();
  },
  async nacexGetTag(expedition) {
    const token = await getToken();

    await fetch(`${API_IDIVIA}nacex/tag/${expedition}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        window.open(file, 'blank');
      });
  },
  async nacexCancelExpedition(expedition) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}nacex/expedition/cancel/${expedition}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* ++++++++++++++++++++++++++DHL+++++++++++++++++++++++++++++ */
  async dhlCreateShipment(
    phone,
    companyName,
    fullName,
    cityName,
    countryCode,
    postalCode,
    addressLine1,
    addressLine2,
    countryName,
    weight,
    length,
    width,
    height,
    order,
    description,
    email,
    volumetric,
    shippingMaterial,
    weight_justification
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/expedition`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        phone: phone,
        companyName: companyName,
        fullName: fullName,
        cityName: cityName,
        countryCode: countryCode,
        postalCode: postalCode,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        countryName: countryName,
        weight: weight,
        length: length,
        width: width,
        height: height,
        order: order,
        description: description,
        email: email,
        shippingCompany: true,
        volumetric: volumetric,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
      }),
    });
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async dhlTracking(expedition) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/tracking/${expedition}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message;
      if (error.additionalDetails == undefined) {
        message = error.detail;
      } else {
        message = error.detail + error.additionalDetails;
      }
      throw Error(message);
    }
  },
  async dhlLabel(expedition) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/label/${expedition}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.detail;
      throw Error(message);
    }
  },
  async dhlCreateShipmentExtraComunitary(
    phone,
    companyName,
    fullName,
    cityName,
    countryCode,
    postalCode,
    addressLine1,
    addressLine2,
    countryName,
    weight,
    length,
    width,
    height,
    order,
    description,
    value,
    currency,
    pdf,
    lineItems,
    invoice,
    email,
    shipping,
    volumetric,
    shippingMaterial,
    weight_justification
  ) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}dhl/expedition/extraComunitary`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          phone: phone,
          companyName: companyName,
          fullName: fullName,
          cityName: cityName,
          countryCode: countryCode,
          postalCode: postalCode,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          countryName: countryName,
          weight: weight,
          length: length,
          width: width,
          height: height,
          order: order,
          description: description,
          value: value,
          currency: currency,
          pdf: pdf,
          lineItems: lineItems,
          invoice: invoice,
          email: email,
          shipping: shipping,
          shippingCompany: true,
          volumetric: volumetric,
          shippingMaterial: shippingMaterial,
          weight_justification: weight_justification,
        }),
      }
    );
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message;
      message = error.message;
      throw Error(message);
    }
  },
  async getDHLCodes() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/pautal/code`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.detail;
      throw Error(message);
    }
  },
  async sendXML(xml, orderId, exp) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/xml/send`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        xml: xml,
        orderId: orderId,
        exp: exp,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateXML(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dhl/xml/update`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* ++++++++++++++++MOLONI++++++++++++++++-- */
  async createSimplifiedInvoices(order) {
    const response = await fetch(
      `${API_IDIVIA}moloni/simplifiedInvoices/create`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          order: order,
          shippingCompany: false,
        }),
      }
    );
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },

  async getPDFLink(id) {
    const response = await fetch(`${API_IDIVIA}moloni/pdf/link`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        idInvoice: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async getPDFFile(id) {
    const response = await fetch(`${API_IDIVIA}moloni/pdf/link`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        idInvoice: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async getInvoice(id) {
    const response = await fetch(`${API_IDIVIA}moloni/getInvoices`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        idInvoice: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async creditNote(idOrder, idInvoice, notes) {
    const response = await fetch(`${API_IDIVIA}moloni/creditNote`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        id: idOrder,
        idInvoice: idInvoice,
        notes: notes,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      let message = error.message;
      throw Error(message);
    }
  },
  async setPendent(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPendent`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async setPendentCS(id, bool) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPendentCS`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        bool: bool,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPendentEdit(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPendentEdit`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPendentPayment(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPendentPayment`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* ++++++++++++++++DPD++++++++++++++++ */
  async dpdCreateShipment(
    id,
    fullName,
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    city,
    state,
    postCode,
    country,
    weight,
    shippingMaterial,
    weight_justification,
    date,
    time
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}dpd/createExpedition`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        fullName: fullName,
        personName: firstName + ' ' + lastName,
        emailAddress: email,
        phoneNumber: phoneNumber,
        companyName: '',
        streetLines: address1,
        city: city,
        stateOrProvinceCode: state,
        postalCode: postCode,
        countryCode: country,
        weight: weight,
        order: id,
        shippingCompany: true,
        addressLine1: address1,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        date: date,
        time: time,
      }),
    });

    if (response.status == 200) {
      /* let i = response.json()
                  console.log(i) */
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw message;
    }
  },

  /* ++++++++++++++++FedEx++++++++++++++++ */
  async fedExCreateShipment(
    id,
    fullName,
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    city,
    state,
    postCode,
    country,
    weight,
    commodities,
    pdf,
    shippingMaterial,
    weight_justification
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}fedex/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        fullName: fullName,
        personName: firstName + ' ' + lastName,
        emailAddress: email,
        phoneNumber: phoneNumber,
        companyName: '',
        streetLines: address1,
        city: city,
        stateOrProvinceCode: state,
        postalCode: postCode,
        countryCode: country,
        weight: weight,
        commodities: commodities,
        pdf: pdf,
        order: id,
        shippingCompany: true,
        addressLine1: address1,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        /* ESTE ULTIMO ADDRESS É PARA  */
      }),
    });

    if (response.status == 200) {
      /* let i = response.json()
                  console.log(i) */
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw message;
    }
  },

  async cancelShipment(tracking, id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}fedex/cancel`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        trackingID: tracking,
        id: id,
      }),
    });

    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error;
      throw Error(message);
    }
  },

  async getCurrencyFedEx(country) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}fedex/currency`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        countryCode: country,
      }),
    });

    if (response.status == 200) {
      /* let i = response.json()
                  console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* ++++++++++++++++CTT++++++++++++++++ */
  async cttCreateShipment(
    order,
    postalCode,
    weight,
    description,
    country,
    orderId,
    shippingMaterial,
    weight_justification
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}ctt/shipment/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: order,
        postal_code: postalCode,
        weight: weight,
        description: description,
        countryCode: country,
        order: orderId,
        shippingCompany: true,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                  console.log(i) */
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.detail || error.message;
      throw Error(message);
    }
  },

  async cttCreateShipmentExtra(
    order,
    postalCode,
    weight,
    description,
    contentPiece,
    fullName,
    cityName,
    addressLine1,
    phone,
    country,
    orderId,
    state,
    ctt_azul,
    shippingMaterial,
    weight_justification
  ) {
    console.log(addressLine1);
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}ctt/shipment/create/extra`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: order,
        postal_code: postalCode,
        weight: weight,
        description: description,
        contentPiece: contentPiece,
        fullName: fullName,
        cityName: cityName,
        addressLine1: addressLine1,
        phone: phone,
        countryCode: country,
        order: orderId,
        shippingCompany: true,
        state: state,
        ctt_azul: ctt_azul,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        ctt_no_iva: true,
      }),
    });
    console.log(response.status);
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message || error;
      throw Error(message);
    }
  },

  async cttLabel(expedition) {
    const token = await getToken();

    await fetch(`${API_IDIVIA}ctt/label/${expedition}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then((res) => res.blob())
      .then((blob) => {
        let w;
        console.log(blob);
        /* let filename = 'TESTE.txt' */
        let file = window.URL.createObjectURL(blob);
        console.log(file);
        /* var pom = document.createElement("a");
                                    pom.setAttribute("href", file);
                                    pom.setAttribute("download", filename);
                                    pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
                                        ":"
                                    );
                                    pom.draggable = true;
                                    pom.classList.add("dragout");
                                    pom.click(); */
        /* window.open(file, 'blank') */
        w = window.open(file, 'blank');
        w.print();
      });
  },
  async cttValid() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/valid`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error;
      throw Error(message);
    }
  },
  async updateCttValid() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/valid/update`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error;
      throw Error(message);
    }
  },
  async cttTrackingsOrder(id) {
    console.log(id);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/trackings/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error;
      throw Error(message);
    }
  },
  /* ++++++++++++++++++++++++++CTT-EXPRESSO+++++++++++++++++++++++++++ */
  async cttExpressoShipment(
    order,
    weight,
    fullName,
    cityName,
    postalCode,
    addressLine,
    phone,
    description,
    orderId,
    length,
    width,
    height,
    shippingMaterial,
    weight_justification
  ) {
    console.log(weight);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: order,
        weight: weight,
        fullName: fullName,
        cityName: cityName,
        postalCode: postalCode,
        addressLine: addressLine,
        phone: phone,
        description: description,
        order: orderId,
        shippingCompany: true,
        addressLine1: addressLine,
        width: width,
        height: height,
        length: length,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        /* ESTE ULTIMO ADDRESS É PARA  */
      }),
    });
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message || error;
      throw Error(message);
    }
  },
  async cttExpresso48h(
    order,
    weight,
    fullName,
    cityName,
    postalCode,
    addressLine,
    phone,
    description,
    orderId,
    length,
    width,
    height,
    customsItems,
    totalProducts,
    country,
    shippingMaterial,
    weight_justification
  ) {
    console.log(weight);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/48h`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: order,
        weight: weight,
        fullName: fullName,
        cityName: cityName,
        postalCode: postalCode,
        addressLine: addressLine,
        phone: phone,
        description: description,
        order: orderId,
        shippingCompany: true,
        addressLine1: addressLine,
        width: width,
        height: height,
        length: length,
        customsItems: customsItems,
        totalProducts: totalProducts,
        countryCode: country,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        /* ESTE ULTIMO ADDRESS É PARA  */
      }),
    });
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error || error;
      throw Error(message);
    }
  },

  async cttExpressoInternationalShipment(
    orders,
    customsItems,
    fullName,
    cityName,
    postalCode,
    addressLine,
    weight,
    length,
    width,
    height,
    phone,
    totalProducts,
    description,
    country,
    orderId,
    shippingMaterial,
    weight_justification
  ) {
    const token = await getToken();
    console.log('PAÍS SERVICE:', country);
    const response = await fetch(`${API_IDIVIA}cttExpresso/international`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: orders,
        customsItems: customsItems,
        fullName: fullName,
        cityName: cityName,
        postalCode: postalCode,
        addressLine: addressLine,
        weight: weight,
        length: length,
        width: width,
        height: height,
        phone: phone,
        totalProducts: totalProducts,
        description: description,
        countryCode: country,
        order: orderId,
        shippingCompany: true,
        addressLine1: addressLine,
        shippingMaterial: shippingMaterial,
        weight_justification: weight_justification,
        /* ESTE ULTIMO ADDRESS É PARA  */
        /* postal_code: postalCode,
                                        weight: weight,
                                        description: description,
                                        length: length,
                                        width: width,
                                        height: height,
                                        contentPiece: contentPiece */
      }),
    });
    if (response.status == 200) {
      let responseJson = await response.json();
      if (responseJson.status == 'cancelled') {
        throw Error('Shipment Cancelled');
      }
      return responseJson;
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message || error;
      throw Error(message);
    }
  },
  async cttExpressoLabel(expedition) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}cttExpresso/label/${expedition}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error;
      throw Error(message);
    }
  },
  async cttShipments() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/shipments`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cttFileToday(shipments, numberInvoice) {
    const token = await getToken();
    console.log(shipments);
    const response = await fetch(`${API_IDIVIA}ctt/cttClose`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        shipments: shipments,
        numberInvoice: numberInvoice,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async dataCttClose(shipments) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/dataCloseCtt`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        shipments: shipments,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async filterCttExpressoShipments(type) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}cttExpresso/filterCloseShipments`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ type: type }),
      }
    );
    console.log(response);
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.error;
      throw Error(message);
    }
  },
  async cttNAllShippingClose() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/closeShipments/all`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cttCancelShipment(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/shipments/cancel`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cttExpressoClose(shipments) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/closeShipments`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        shipments: shipments,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cttExpressoCancelShipment(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/shipments/cancel`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async cttAllShippingClose() {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}cttExpresso/closeShipments/all`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async cttExpressoShipments() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}cttExpresso/shipments`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  /* ++++++++++++++++----EMAIL++++++++++++++++- */
  async sendEmail(order, company, trackingId, resend, price_eur) {
    const response = await fetch(`${API_IDIVIA}sendEmail`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        order: order,
        company: company,
        trackingId: trackingId,
        resend: resend,
        price_eur: price_eur,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  /* #####################PURCHASES#################### */
  async getTransactionsPurchase() {
    console.log('ENTROU NO PURCHASE FETCH');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/getTransit`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getProductsTransactionsRequest() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/getComprasPendentes`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Purchases
  async getMissingProducts() {
    const token = await getToken();
    console.log('TOKEN', token);
    const response = await fetch(`${API_IDIVIA}purchases/getMissing`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getProductExtraInfoPur(sku) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/getExtraInfo`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addProductExtraInfoPur(name, sku, ean) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/addExtraProduct`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        sku: sku,
        ean: ean,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addTransactionPurchase(
    name,
    sku,
    ean,
    supplier,
    qty_order,
    price,
    qty_confirmed,
    email,
    farma_email,
    discount,
    id_prod,
    id_supp
  ) {
    console.log(
      'FARMA ADD',
      sku,
      supplier,
      qty_order,
      price,
      qty_confirmed,
      email,
      farma_email,
      discount,
      id_prod
    );
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/createTrans`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        sku: sku,
        ean: ean,
        supplier: supplier,
        qty_request: qty_order,
        price: price,
        qty_confirmed: qty_confirmed,
        email: email,
        farma_email: farma_email,
        discount: discount,
        id_prod: id_prod,
        id_supp: id_supp,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getFarmaTransactions(supplier) {
    console.log('FARMA REQ');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/getOrdersReq`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        supplier: supplier,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getFarmaTransactionsConf() {
    console.log('FARMA COONF');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/getOrdersConf`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async confirmTransaction(id, sku, qty_confirmed, supplier, toLab) {
    console.log('FARMA Trans');
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}purchases/confirmOrder`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        sku: sku,
        qty_confirmed: qty_confirmed,
        supplier: supplier,
        fromLab: toLab,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateTransaction(id, sku, qty_confirmed) {
    console.log('FARMA Trans');
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}purchases/updateOrder`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        sku: sku,
        qty_confirmed: qty_confirmed,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cancelTransaction(id) {
    console.log('FARMA Trans');
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/cancelOrder`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async sendEmailMiss(obj) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}purchases/sendMiss`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        obj: obj,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async cancelFarmasTransactions(ids) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}purchases/cancelFarmasTransactions`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ids: ids,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateProducts() {
    console.log('Update Products');
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}wordpress/products/toDB`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async createInvoideTrans(
    id,
    supplier,
    generalDiscount,
    valueExtra,
    paymentMethod
  ) {
    console.log('Create Invoice Trans');
    console.log(id);
    const token = await getToken();

    console.log();
    const response = await fetch(`${API_IDIVIA}purchases/createInvoice`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        id_supp: supplier,
        generalDiscount: generalDiscount,
        extraValue: valueExtra,
        paymentMethod: paymentMethod,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async createInvoiceTransExtern(
    id,
    supplier,
    generalDiscount,
    valueExtra,
    paymentMethod,
    addToAccounting
  ) {
    console.log('Create Invoice Trans');
    console.log(id);
    const token = await getToken();

    console.log();
    const response = await fetch(`${API_IDIVIA}purchases/createInvoice`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        id_supp: supplier,
        generalDiscount: generalDiscount,
        extraValue: valueExtra,
        paymentMethod: paymentMethod,
        addToAccounting: addToAccounting,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addProduct(name, price, sku, ean, stock, weight, validade, tax_iva) {
    console.log('Add Product');
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/add`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        price: price,
        sku: sku,
        ean: ean,
        stock: stock,
        weight: weight,
        validade: validade,
        tax_iva: tax_iva,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateStockStatus(id, status) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/updateStock`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updatePriceOption(id, status) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}products/priceOption`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Tracking Relatorio
  async getTrackingNotDel() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}tracking/notDel`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setDelivered(order_id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}tracking/setDel`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: order_id,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getTracking(order) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}tracking/get`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: order,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async sendEmailTransport(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}tracking/sendBefore`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async sendEmailTransportS(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}tracking/sendBeforeS`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async productPurchase(id) {
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}purchases/transactions/products`,
      {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
        }),
      }
    );
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //PICKING

  async calculatePicking() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}picking/calculate`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async updatePurchase(
    articles,
    supplier,
    invoiceId,
    paymentMethod,
    totalPay,
    discount,
    id,
    creditNoteValue,
    creditNoteNumber
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}suppliers/invoices/updateData`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        transactions: articles,
        id_supp: supplier,
        bill_id: invoiceId,
        payment_method: paymentMethod,
        invoiceValue: totalPay,
        discount: discount,
        creditNoteValue: creditNoteValue,
        creditNoteNumber: creditNoteNumber,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async pickingByID(id) {
    const token = await getToken();
    console.log(`${API_IDIVIA}picking/pickingID `, id);
    const response = await fetch(`${API_IDIVIA}picking/pickingID`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPriority(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}picking/makePriority`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async printOrders(orders) {
    console.log('IMPRIMIR', orders);
    const token = await getToken();
    console.log(`IMPRIMIR ${API_IDIVIA}picking/printTicket`);

    const response = await fetch(`${API_IDIVIA}picking/printTicket`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        orders: orders,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  //Price Auto
  async getPriceVariables() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/price`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updatePriceVariables(
    site,
    margin,
    free_shipping,
    payment_perc,
    kg_price,
    mult_price,
    sum_price
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/price`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        site: site,
        margin: margin,
        free_shipping: free_shipping,
        payment_perc: payment_perc,
        kg_price: kg_price,
        mult_price: mult_price,
        sum_price: sum_price,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getPriceIntervalos() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/intervalPrice`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addIntervaloPrice(
    price_initial,
    price_final,
    price_mult,
    price_sum,
    price_fix,
    weight_initial,
    weight_final,
    weight_mult,
    weight_sum,
    weight_fix,
    site
  ) {
    console.log(
      'TESTE PQP',
      price_final,
      price_initial,
      price_mult,
      price_sum,
      price_fix,
      weight_initial,
      weight_final,
      weight_mult,
      weight_sum,
      weight_fix,
      site
    );
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/intervalPrice`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        price_initial: price_initial ?? undefined,
        price_final: price_final ?? undefined,
        price_mult: price_mult ?? undefined,
        price_sum: price_sum ?? undefined,
        price_fix: price_fix ?? undefined,
        weight_initial: weight_initial ?? undefined,
        weight_final: weight_final ?? undefined,
        weight_mult: weight_mult ?? undefined,
        weight_sum: weight_sum ?? undefined,
        weight_fix: weight_fix ?? undefined,
        site: site,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateIntervaloPrice(
    id,
    price_initial,
    price_final,
    price_mult,
    price_sum,
    price_fix,
    weight_initial,
    weight_final,
    weight_mult,
    weight_sum,
    weight_fix
  ) {
    const token = await getToken();
    console.log(
      'TESTE PQP',
      price_final,
      price_initial,
      price_mult,
      price_sum,
      price_fix,
      weight_initial,
      weight_final,
      weight_mult,
      weight_sum,
      weight_fix
    );

    const response = await fetch(`${API_IDIVIA}admin/intervalPrice`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        price_initial: price_initial ?? undefined,
        price_final: price_final ?? undefined,
        price_mult: price_mult ?? undefined,
        price_sum: price_sum ?? undefined,
        price_fix: price_fix ?? undefined,
        weight_initial: weight_initial ?? undefined,
        weight_final: weight_final ?? undefined,
        weight_mult: weight_mult ?? undefined,
        weight_sum: weight_sum ?? undefined,
        weight_fix: weight_fix ?? undefined,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteIntervaloPrice(id) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}admin/intervalPrice`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  //Shipping
  async getShippingVariables() {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}shipping/getVariables`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async updateShippingVariables(
    company,
    fuel,
    inflation,
    insurance,
    bad_service,
    bad_service_vol
  ) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}shipping/updateVariables`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: {
          company: company,
          fuel: fuel,
          inflation: inflation,
          insurance: insurance,
          bad_service: bad_service,
          bad_service_vol: bad_service_vol,
        },
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async calculateShipping(
    order_id,
    country,
    weight,
    price,
    height,
    width,
    length1,
    skip
  ) {
    const token = await getToken();
    console.log(`${API_IDIVIA}shipping/calculate`);

    let body = {
      order_id: order_id,
      country: country,
      weight: weight,
      price: price,
    };

    if (height != undefined && width != undefined && length1 != undefined) {
      body = {
        order_id: order_id,
        country: country,
        weight: weight,
        price: price,
        measurements: {
          height: height,
          width: width,
          length: length1,
        },
      };
    }

    if (skip != undefined) {
      body.skip = skip;
    }

    const response = await fetch(`${API_IDIVIA}shipping/calculate`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async cs_shipping_company(id, company) {
    const token = await getToken();
    console.log(`IMPRIMIR ${API_IDIVIA}orders/changeShip`);

    const response = await fetch(`${API_IDIVIA}orders/changeShip`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        company: company,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async salesReport() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}productTrans/getVendas`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async salesReportPicked(start_date, final_date) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}productTrans/getPicked`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        start_date: start_date,
        final_date: final_date,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async salesReportAnual(year) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}productTrans/getVendasAnual`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        year: year,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async salesReportByCountry(country) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}productTrans/getVendasByCountry`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          country: country,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      let message = error.message;
      throw Error(message);
    }
  },
  async salesReportByCountryDate(country, start_date, final_date) {
    alert('AQUI');
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}productTrans/getVendasByCountryDate`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          country: country,
          start_date: start_date,
          final_date: final_date,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      let message = error.message;
      throw Error(message);
    }
  },
  async productPurchaseLock(sku, motivo) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/lockSKU`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        motivo: motivo,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async productPurchaseUnlock(sku) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/unlockSKU`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  //-------------- NEW ORDERS --------------
  async getOrdersPendent() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getPendent`);

    const response = await fetch(`${API_IDIVIA}search/getPendent`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPendentCS() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getPendentCS`);

    const response = await fetch(`${API_IDIVIA}search/getPendentCS`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPendentEdit() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getPendentEdit`);

    const response = await fetch(`${API_IDIVIA}search/getPendentEdit`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPendentPayment() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getPendentPayment`);

    const response = await fetch(`${API_IDIVIA}search/getPendentPayment`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPendentKK() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getKKPendent`);

    const response = await fetch(`${API_IDIVIA}search/getKKPendent`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersProcessingNew() {
    const token = await getToken();
    console.log(`Processing ${API_IDIVIA}search/getProcessing`);

    const response = await fetch(`${API_IDIVIA}search/getProcessing`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPicking() {
    const token = await getToken();
    console.log(`Picking ${API_IDIVIA}search/getPicked`);

    const response = await fetch(`${API_IDIVIA}search/getPicked`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPrinting() {
    const token = await getToken();
    console.log(`Picking ${API_IDIVIA}search/getPrinted`);

    const response = await fetch(`${API_IDIVIA}search/getPrinted`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersPacking() {
    const token = await getToken();
    console.log(`Packing ${API_IDIVIA}search/getPacking`);

    const response = await fetch(`${API_IDIVIA}search/getPacking`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersReturned() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}search/getReturned`);

    const response = await fetch(`${API_IDIVIA}search/getReturned`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPicking(order) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPicking`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: order,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPrinting(order) {
    const token = await getToken();
    console.log('SET PRINTING');

    const response = await fetch(`${API_IDIVIA}orders/setPrinting`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: order,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async setPacking(orders) {
    const token = await getToken();

    const response = await fetch(`${API_IDIVIA}orders/setPacking`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: orders,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getReasonsDelay() {
    const token = await getToken();
    console.log(`Pendent ${API_IDIVIA}delay/delay`);

    const response = await fetch(`${API_IDIVIA}delay/delay`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async sendReasonDelayEmail(order_id, reason) {
    const token = await getToken();
    console.log(order_id, reason);
    console.log(`Pendent ${API_IDIVIA}delay/delay`);

    const response = await fetch(`${API_IDIVIA}delay/delay`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: order_id,
        reason: reason,
      }),
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  async countDaysToDelivered(company) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/days`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        company: company,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async daysToEvaluation() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/daysToSendEmail`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateDaysToEvaluation(id, newDays) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/editDays`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        days: newDays,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateDaysToDelivered(id, newDays) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/editDaysToDelivered`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        days: newDays,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteEmail(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/email`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addEmailEvaluation(
    name,
    email,
    dateStart,
    dateEnd,
    emailPortugues,
    emailSpanish,
    emailJapanese
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/addEmail`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        dateStart: dateStart,
        dateEnd: dateEnd,
        email: email,
        emailPortugues: emailPortugues,
        emailSpanish: emailSpanish,
        emailJapanese: emailJapanese,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getEmailMarketing(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/email`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateEmailMarketing(
    id,
    email,
    dateStart,
    dateEnd,
    name,
    emailPortugues,
    emailSpanish,
    emailJapanese
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}marketing/editEmail`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        dateStart: dateStart,
        dateEnd: dateEnd,
        email: email,
        emailPortugues: emailPortugues,
        emailSpanish: emailSpanish,
        emailJapanese: emailJapanese,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async validityReport() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/validityReport`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async filterValidityReport(month) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}products/validityReport/filter`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          month: month,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Product Names Pendent
  async getPendentNames() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}novaEngel/names`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updatePendentName(id_backoffice) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}novaEngel/names`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_backoffice: id_backoffice,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async internReturn(id, newInvoice, justification) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/internReturn`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        newInvoice: newInvoice,
        justification: justification,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async lostOrder(id, status) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/lost`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async duplicateOrder(id, newInvoice, notes, creditNote) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/duplicate`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        newInvoice: newInvoice,
        notes: notes,
        creditNote: creditNote,
        status: status,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getOrdersLost() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/lost`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async statusUpdate(id, status) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/status`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAllValidationAddress() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}search/getValidationAddress`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async apiTest() {
    const response = await fetch(`http://18.202.191.98/api/apiTest`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });
    if (response.status == 200) {
      console.log('Correu bem no idiviaService');
      return response.json();
    }
  },
  async approveValidationAddress(
    id,
    address,
    firstName,
    lastName,
    city,
    postalCode,
    state,
    country,
    validateAddress,
    notes
  ) {
    console.log(id, address, city, postalCode, country);
    const token = await getToken();

    const response = await fetch(
      `${API_IDIVIA}orders/validationAddress/approve`,
      {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          firstName: firstName,
          lastName: lastName,
          address: address,
          city: city,
          CP: postalCode,
          state: state,
          country: country,
          validateAddress: validateAddress,
          notes: notes,
        }),
      }
    );
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async sendEmailToApproveAddress(
    id,
    email,
    firstName,
    secondName,
    address,
    city,
    state,
    postCode,
    country,
    phone
  ) {
    console.log('PEDIDO API');
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}orders/validationAddress/email`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          email: email,
          firstName: firstName,
          secondName: secondName,
          address1: address,
          city: city,
          postCode: postCode,
          state: state,
          country: country,
          phone: phone,
        }),
      }
    );
    if (response.status == 200) {
      /* let i = response.json()
                              console.log(i) */
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addressValidationStatus(status) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}settings/validationAdress/`, {
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        status: status,
      }),
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getValidationAddress() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/validationAddress`, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },

  //Discounts
  async getDiscounts() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async createDiscount(
    site,
    brand,
    start_date,
    end_date,
    margin,
    free_shipping,
    payment_perc,
    kg_price,
    limit_discount,
    priority
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        site: site,
        brand: brand,
        start_date: start_date,
        end_date: end_date,
        margin: margin,
        free_shipping: free_shipping,
        payment_perc: payment_perc,
        kg_price: kg_price,
        limit_discount: limit_discount,
        priority: priority,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateDiscount(
    id,
    start_date,
    end_date,
    margin,
    free_shipping,
    payment_perc,
    kg_price,
    limit_discount,
    priority
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts/${id}`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        start_date: start_date,
        end_date: end_date,
        margin: margin,
        free_shipping: free_shipping,
        payment_perc: payment_perc,
        kg_price: kg_price,
        limit_discount: limit_discount,
        priority: priority,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteDiscount(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getDiscountsProducts(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async discountsProducts(id, products) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/discounts/${id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        products: products,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Stock Status Variables
  async getStockStatusVar() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/stockStatus`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateStockStatusVar(type, max, min, price) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/stockStatus`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        type: type,
        max: max,
        min: min,
        price: price,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Price Options Variables
  async getPriceOptionsVar() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/priceOptions`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updatePriceOptionsVar(
    level,
    margin,
    validity,
    last_reception,
    last_sale,
    stock,
    auto_days
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/priceOptions`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        level: level,
        margin: margin,
        validity: validity,
        last_reception: last_reception,
        last_sale: last_sale,
        stock: stock,
        auto_days: auto_days,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Variable Update Site
  async getUpdateVariables() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/stock`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateUpdateVariables(site, price_perc, stock_perc) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}admin/stock`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        site: site,
        price_perc: price_perc,
        stock_perc: stock_perc,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //Join Products
  async joinProducts(sku_original, sku_to_join) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/join`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku_original,
        join_sku: sku_to_join,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAllFeedSettings() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/settings`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAllFeedValues() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/values`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateFeedValues(feed, soma, multiplo, margem, fee, shipping) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/values`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        feed: feed,
        soma: soma,
        multiplo: multiplo,
        margem: margem,
        fee: fee,
        shipping: shipping,
      }),
    });
    if (response.status == 200) {
      return response.json();
    }
  },
  async updateFeedSettings(
    feed,
    ocp_stock,
    nova_engel,
    farmas_stock,
    only_site
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/settings`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        feed: feed,
        ocp_stock: ocp_stock,
        nova_engel: nova_engel,
        farmas_stock: farmas_stock,
        only_site: only_site,
      }),
    });
    if (response.status == 200) {
      return response.json();
    }
  },
  async addIntervalFeed(
    price_initial,
    price_final,
    price_mult,
    price_sum,
    price_fix,
    weight_initial,
    weight_final,
    weight_mult,
    weight_sum,
    weight_fix,
    margem,
    feed,
    country
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/intervalos`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        price_initial: price_initial,
        price_final: price_final,
        price_mult: price_mult,
        price_sum: price_sum,
        price_fix: price_fix,
        weight_initial: weight_initial,
        weight_final: weight_final,
        weight_mult: weight_mult,
        weight_sum: weight_sum,
        weight_fix: weight_fix,
        margem: margem,
        feed: feed,
        country: country,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getIntervalFeed() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/intervalos`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getMargemFeedByFeed(feed) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/margem/company`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        feed: feed,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteMargemFeed(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/margem`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getMissingProductsBrands() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/brands/missing`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getManualPrice() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/manualPrice/report`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateProductBrand(id, brand) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/brand`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_backoffice: id,
        brand_id: brand,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addShippingFeed(feed, shipping, maximo, minimo, country) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/shipping`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        feed: feed,
        shipping: shipping,
        maximo: maximo,
        minimo: minimo,
        country: country,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getShippingFeed() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/shipping`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async editMargemInterval(
    id,
    price_initial,
    price_final,
    price_mult,
    price_sum,
    price_fix,
    weight_initial,
    weight_final,
    weight_mult,
    weight_sum,
    weight_fix,
    margem
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/intervalos`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        price_initial: price_initial,
        price_final: price_final,
        price_mult: price_mult,
        price_sum: price_sum,
        price_fix: price_fix,
        weight_initial: weight_initial,
        weight_final: weight_final,
        weight_mult: weight_mult,
        weight_sum: weight_sum,
        weight_fix: weight_fix,
        margem: margem,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async addMargemFeed(feed, margem, maximo, minimo, country) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/margem`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        feed: feed,
        margem: margem,
        maximo: maximo,
        minimo: minimo,
        country: country,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getMargemFeed() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/margem`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteShippingFeed(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/shipping`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteIntervalFeed(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/intervalos`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async noProductsFeed(sku, feed, nameProduct, id_product) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/noFeedProducts`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        feed: feed,
        nameProduct: nameProduct,
        id_product: id_product,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getNoProductsFeed() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/noFeedProducts`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async deleteNoProductFeed(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/noFeedProducts`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async filterNoProductsFeed(sku, feed) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feed/filterNoFeedProducts`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        feed: feed,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async aerosolProductsReport() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/aerosol/report`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateAerosolProduct(id, aerosol) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/aerosol`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_backoffice: id,
        aerosol: aerosol,
      }),
    });
    if (response.status == 200) {
      console.log(response);
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateTicketTracking(order_id, ticket_id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/ticket`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: order_id,
        ticket_id: ticket_id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  /* SPECIAL OFFERS FROM CLIENTS */
  async addClientRequest(
    sku,
    name,
    img,
    qty,
    price_sale,
    product_link,
    status,
    type,
    comments,
    id_backoffice,
    urgent
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/request`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        name: name,
        img: img,
        qty: qty,
        price_sale: price_sale,
        product_link: product_link,
        status: status,
        type: type,
        comments: comments,
        id_backoffice: id_backoffice,
        urgent: urgent,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClientRequests() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/request`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getAllClientRequests() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/requests`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async associateProduct(id, id_backoffice, sku_client, email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/associateProducts`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        id_backoffice: id_backoffice,
        sku_client: sku_client,
        email: email,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateClientOffer(
    id,
    status,
    price_offer,
    qty_accepted,
    actual_status,
    save_price,
    id_backoffice,
    client_email
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/makeOffer`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
        price_offer: price_offer,
        qty_accepted: qty_accepted,
        actual_status: actual_status,
        save_price: save_price,
        id_backoffice: id_backoffice,
        client_email: client_email,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateClientCounterOffer(id, status, counter_offer) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/counterOffer`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
        counter_offer: counter_offer,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async cancelClientRequest(id, status) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/cancel`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async acceptRequest(id, status, price_final, email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/accept`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
        price_final: price_final,
        email: email,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async filterClientRequests(status, sku, name) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/filter`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        status: status,
        sku: sku,
        name: name,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClientRequestLastUpdated() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/lastUpdated`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async allLastUpdateRequests() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/allLastUpdated`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async filterAllRequests(status, sku, email, name) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/filterAllRequests`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        status: status,
        sku: sku,
        email: email,
        name: name,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addCommentToRequest(id, comment, oldComments) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/addComment`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        comment: comment,
        oldComment: oldComments,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async editRequestClient(
    id,
    img,
    name,
    sku,
    price_sale,
    product_link,
    type,
    qty,
    counterOffer,
    priceOffer,
    price,
    qty_accepted
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/editRequest`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        img: img,
        name: name,
        sku: sku,
        price_sale: price_sale,
        product_link: product_link,
        type: type,
        qty: qty,
        counterOffer: counterOffer,
        priceOffer: priceOffer,
        price: price,
        qty_accepted: qty_accepted,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClientProduct(sku) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/getClientProduct`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async allProductClient() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/getAllProductsClient`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addProductClient(sku, name, img, product_link) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/addProduct`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku_client: sku,
        name: name,
        img: img,
        product_link: product_link,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async editProductClient(sku, name, img, product_link, price_sale) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/editProduct`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku_client: sku,
        name: name,
        img: img,
        product_link: product_link,
        price_sale: price_sale,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async createOrderToClientRequest(products) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}client/createOrderToClientRequest`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          products: products,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async searchClientAddress(email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/searchClient`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: email }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async filterRequestsAndPicking(email) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}client/filterRequestsAndPicking`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: email }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async countEmailRequest() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/countRequests`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async requestClientCreateOrder(
    email,
    firstName,
    lastName,
    phone,
    address1,
    address2,
    city,
    state,
    postCode,
    country,
    orderProducts,
    shippingCompany,
    total,
    shippingLines,
    backupProduct,
    exchange
  ) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}client/requestClientCreateOrder`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: email,
          firstName: firstName,
          secondName: lastName,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          postCode: postCode,
          country: country,
          phone: phone,
          orderProducts: orderProducts,
          shippingCompany: shippingCompany,
          total: total,
          shippingLines: shippingLines,
          backupProduct: backupProduct,
          exchange: exchange,
        }),
      }
    );
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async financialSheet(id, finance_sheet) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/financialSheet`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        finance_sheet: finance_sheet,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async cancelRequestClient(id, cancelStatus) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/cancelRequest`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        cancel_status: cancelStatus,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async sentRequestClient(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/sendRequest`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async backinStatusRequestClient(id, status, email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/backInStatus`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        status: status,
        email: email,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClienteDiscount(email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/discount`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: email }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async allClientsDiscounts() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/allDiscounts`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateDiscountClients(id, discount) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/discount`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id, discount: discount }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getAllOrdersClient() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/allOrders`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async logsClientRequest(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/logs`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Clients Orders Info
  async searchClients(name, email, phone) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/search`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: name, email: email, phone: phone }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClientInfo(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateClientInfo(
    id,
    first_name,
    last_name,
    email,
    phone,
    notes,
    emails_address,
    emails_tracking,
    scam
  ) {
    console.log(
      id,
      first_name,
      last_name,
      email,
      phone,
      notes,
      emails_address,
      emails_tracking,
      scam
    );
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/${id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        notes: notes,
        emails_address: emails_address,
        emails_tracking: emails_tracking,
        scam: scam,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getClientOrder(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/order/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async getClientOrderShipping(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/orderShipping/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async filterClientOrders(status) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/orders`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: status }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async searchClientOrders(status, id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/searchOrders`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status: status, id: id }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else if (response.status == 404) {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async allProductsClientPrice(client, sku, active) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}client/allPricesProductsClients`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          client: client,
          sku: sku,
          active: active,
        }),
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async allClientsInProductsPrice() {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}client/allClientsListInProductsPrice`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async updateClientsProductsPrice(id, price_eur, active, updatePrice) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/updateProductsPrice`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        price_eur: price_eur,
        active: active,
        updatePrice: updatePrice,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  async removeClientsProductsPrice(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}client/deleteProductPrice`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.message;
      throw Error(message);
    }
  },
  //Clients Addresses
  async addClientsAddress(
    id,
    address,
    address2,
    city,
    state,
    postcode,
    country
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/addresses/${id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        address: address,
        address2: address2,
        city: city,
        state: state,
        postcode: postcode,
        country: country,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateClientsAddress(
    id,
    address_id,
    address,
    address2,
    city,
    state,
    postcode,
    country
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/addresses/${id}`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        address_id: address_id,
        address: address,
        address2: address2,
        city: city,
        state: state,
        postcode: postcode,
        country: country,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deleteClientsAddress(id, address_id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}clientsOrder/addresses/${id}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        address_id: address_id,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //Translate
  async translateInfo(address, city, state) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/translate`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        address: address,
        city: city,
        state: state,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async editProductImage(id, img) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}products/editProductImage`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        img: img,
      }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
    }
  },
  // DANIELA PLATFORM - BUY TO BRANDS
  async getAllBrandPurchase() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/brandsPurchase`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async createNewTransactionsFromBrandsPurchase(products) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}purchases/createNewTransactionsFromBrandsPurchase`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ products: products }),
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addProductToBrandsPurchase(sku) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}purchases/productBrandsPurchase/${sku}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async sendEmailToLab(email, lab, products) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/sendEmailToLab`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email: email, products: products, lab: lab }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async allLaboratories() {
    const Token = await getToken();
    const response = await fetch(`${API_IDIVIA}laboratories`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${Token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  async updateBrandLab(id, lab) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}brands/updateBrandLab/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ lab: lab }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async danielaPurchase() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/danielaPurchase`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async cancelBrandsPurchase(id) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}purchases/cancelBrandsPurchase`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: id }),
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async farmasLabPurchase() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/labTransToFarma`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateLabPurchase(
    sku,
    newQty,
    to_alvim,
    to_martins,
    to_gomes,
    to_perelhal,
    to_idivia,
    id,
    idTransaction
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/editLabPurchase`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        sku: sku,
        newQty: newQty,
        to_alvim: to_alvim,
        to_martins: to_martins,
        to_gomes: to_gomes,
        to_perelhal: to_perelhal,
        to_idivia: to_idivia,
        id: id,
        idTransaction: idTransaction,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async filterLabsPurchases(lab, status, dateInitial, dateFinal, sku) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/filterLabsPurchases`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        lab: lab,
        status: status,
        dateImitial: dateInitial,
        dateFinal: dateFinal,
        sku: sku,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async lateLabPurchases(id_trans, id_daniela) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/lateRequestLab`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_daniela: id_daniela,
        id_transaction: id_trans,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async confirmDanielaPurchase(id_trans, id_daniela) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/comfirmLabsPurchase`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id_daniela: id_daniela,
        id_transaction: id_trans,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async editQtyRequestLab(id_trans, newQty) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}purchases/editQtyRequestLab`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id_transaction: id_trans, newQty: newQty }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async insertFileCSV(file, type) {
    const token = await getToken();
    let formData = new FormData();
    formData.append('csv', file);
    formData.append('typeImport', type);
    const response = await fetch(`${API_IDIVIA}imports/insertFileCSV`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async allImports() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}imports/allImports`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async downloadImports(type) {
    console.log('type', type);
    const token = await getToken();
    let formData = new FormData();
    formData.append('type', type);
    let response = await fetch(`${API_IDIVIA}imports/exportTemplate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) {
      response
        .blob()
        .then((blob) => {
          let newBlob = new Blob([blob]);
          const data = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = data;
          if (type == 'prices') link.download = 'template_products_price.csv';
          // change this to the desired filename
          else if (type == 'names')
            link.download = 'template_products_name.csv'; // change this to the desired filename
          link.click();
        })
        .catch((error) => {
          console.log(response.status);
          console.log(error);
        });
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 500) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getFileImported(fileName) {
    const token = await getToken();
    let response = await fetch(`${API_IDIVIA}imports/getFileImported`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ fileName: fileName }),
    });
    if (response.status == 200) {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        })
        .catch((error) => {
          console.log(response.status);
          console.log(error);
        });
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else if (response.status == 500) {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addInsurance(tracking, value) {
    console.log('tracking', tracking, 'value', value);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/insurance`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ tracking: tracking, value: value }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async allInsurance() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}orders/insurance`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //CATEGORIES
  async allCategories() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/categories`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addCategory(name) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/categories`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: name }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deleteCategory(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/categories`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getCategoriesProducts(id, name) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/categories/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = url;
          link.download = `${name}.csv`;
          link.click();
        })
        .catch((error) => {
          console.log(response.status);
          console.log(error);
        });
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateCategoriesProducts(id, file) {
    const token = await getToken();
    let formData = new FormData();
    formData.append('csv', file);
    const response = await fetch(`${API_IDIVIA}feedCountry/categories/${id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //PRODUCTS FEEDS
  async getProductsCategories(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/products/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateProductsCategories(id, categories) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/products/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ categories: categories }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  async updateCategoriesViaSku(sku, categories) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/update_sku`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ sku: sku, categories: categories }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //FEEDS BY COUNTRY
  async allFeedsByCountry() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/feeds`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addFeedByCountry(
    name,
    countrys,
    excluded_categories,
    language,
    round,
    only_stock,
    free_shipping,
    vendas,
    date_vendas
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/feeds`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        countrys: countrys,
        excluded_categories: excluded_categories,
        language: language,
        round: round,
        only_stock: only_stock,
        free_shipping: free_shipping,
        vendas: vendas,
        date_vendas: date_vendas,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateFeedByCountry(
    id,
    name,
    countrys,
    excluded_categories,
    language,
    round,
    only_stock,
    free_shipping,
    vendas,
    date_vendas
  ) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/feeds`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        name: name,
        countrys: countrys,
        excluded_categories: excluded_categories,
        language: language,
        round: round,
        only_stock: only_stock,
        free_shipping: free_shipping,
        vendas: vendas,
        date_vendas: date_vendas,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deleteFeedByCountry(id) {
    console.log('id', id);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/feeds`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async generateFeedByCountry() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/generate`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        request: true,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  //Coins
  async getCoins() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/coins`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateCoins(coin, rate) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/coins`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        coin: coin,
        rate: rate,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  //CUSTOM LABELS
  async getCustomLabels() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/custom_labels`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateCustomLabels(name, min, med, max, weight) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}feedCountry/custom_labels`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        min: min,
        med: med,
        max: max,
        weight: weight,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async checkIfWasInternReturn(id) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}orders/checkIfWasInternReturn/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  async requestForInvoiceToSupplier(id, email, products) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}suppliers/requestForInvoiceToSupplier`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          email: email,
          products: products,
        }),
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },

  //ctt
  async getcttEmails() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/cttEmail`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addcttEmails(info) {
    console.log('info', info);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/cttEmail`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ info: info }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updatecttEmails(id, ticket_id, next_email) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/cttEmail`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        ticket_id: ticket_id,
        next_email: next_email,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deletecttEmails(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}tracking/cttEmail`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: id }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addDeliveryNoteCTT(date, file) {
    const token = await getToken();
    let formData = new FormData();
    console.log(file);
    formData.append('file', file);
    formData.append('date', date);
    const response = await fetch(`${API_IDIVIA}ctt/deliveryNote`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getDeliveryNoteCTT(date_ini, date_fin) {
    console.log('date_ini', date_ini, 'date_fin', date_fin);
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}ctt/deliveryNote`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ init_date: date_ini, final_date: date_fin }),
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //ACCOUNTING
  async accountingAllSuppliersWithDifference() {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}accounting/suppliersWithDifference`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async accountingBySupplier(id, date_start, date_end) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/suppliers/${id}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        date_start: date_start,
        date_end: date_end,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addAccounting(document, supplier, value, reception_id, invoice, date) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        document: document,
        supplier: supplier,
        value: value,
        reception_id: reception_id,
        transaction_detail: invoice,
        date: date,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async accountingAllReceptions(id) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}accounting/receptions/suppliers/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async receptionsToReceive(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/receive/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async receptionsToCreditNote(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/creditNote/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async searchByBill(id, search) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/search/${id}/bill`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        search: search,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addCreditNoteFile(
    id,
    file,
    creditNoteNumber,
    creditNoteValue,
    supplier
  ) {
    const token = await getToken();
    let formData = new FormData();
    console.log(file);
    formData.append('file', file);
    formData.append('id', id);
    formData.append('creditNoteNumber', creditNoteNumber);
    formData.append('creditNoteValue', creditNoteValue);
    formData.append('supplier', supplier);
    const response = await fetch(`${API_IDIVIA}suppliers/creditNote/file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    if (response.status == 200) {
      return response.json();
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      let message = error.message;
      throw Error(message);
    } else {
      let error = await response.json();
      console.log(error);
      let message = error.data;
      throw Error(message);
    }
  },
  async filterBySupplierAccounting(id) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}accounting/suppliers/filter/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async searchByDetailsAccounting(date_start, date_end, document) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/search/details`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        date_start: date_start,
        date_end: date_end,
        document: document,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getReceptionsToPay(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}accounting/payments/${id}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async activateCreditNote(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}suppliers/creditNote/activate`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //Product Material
  async getProductsTranMaterial(id) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}productMaterial/productTran/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //Relatórios
  async getSalesByCountryProduct(product, country) {
    const token = await getToken();
    const response = await fetch(
      `${API_IDIVIA}productTrans/getVendasByCountryProduct`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Add this line
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product: product,
          country: country,
        }),
      }
    );
    if (response.status == 200) {
      response
        .json()
        .then((data) => {
          if (data.status === 'success') {
            const csvData = data.data; // Assuming this is an array of objects
            const csv = this.convertToCSV(csvData);

            // Create a Blob from the CSV string
            let blob = new Blob([csv], { type: 'text/csv' });
            let url = window.URL.createObjectURL(blob);

            // Create a download link and trigger it
            let link = document.createElement('a');
            link.href = url;
            link.download = `${product}_${country}.csv`;
            link.click();
          }
        })
        .catch((error) => {
          console.log('Error while parsing JSON:', error);
        });
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getBrandSales(start_date, end_date) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}productTrans/getBrand`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Add this line
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        start_date: start_date,
        final_date: end_date,
      }),
    });
    if (response.status == 200) {
      response
        .json()
        .then((data) => {
          if (data.status === 'success') {
            const csvData = data.data; // Assuming this is an array of objects
            const csv = this.convertToCSV(csvData);

            // Create a Blob from the CSV string
            let blob = new Blob([csv], { type: 'text/csv' });
            let url = window.URL.createObjectURL(blob);

            // Create a download link and trigger it
            let link = document.createElement('a');
            link.href = url;
            link.download = `${start_date}_${end_date}_sales.csv`;
            link.click();
          }
        })
        .catch((error) => {
          console.log('Error while parsing JSON:', error);
        });
    } else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getDashboardInfo() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}dashboard/`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getShippingReport(ini_date, fin_date) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}shipping/list`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ini_date: ini_date,
        fin_date: fin_date,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async getShippingMaterial(ini_date, fin_date) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}shipping/listMat`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ini_date: ini_date,
        fin_date: fin_date,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //Nova Engel Stock Conditions
  async getNovaEngelStockCond() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}management/novaEngel`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async createNovaEngelStockCond(brand_id, division, stock_min, max_division) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}management/novaEngel`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        brand_id: brand_id,
        division: division,
        stock_min: stock_min,
        max_division: max_division,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateNovaEngelStockCond(id, division, stock_min, max_division) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}management/novaEngel`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        division: division,
        stock_min: stock_min,
        max_division: max_division,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deleteNovaEngelStockCond(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}management/novaEngel`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //FEED KK
  async getKKIntervals() {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/intervals`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async addKKIntervals(price_ini, price_final, margin, mult, soma) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/intervals`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        price_ini: price_ini,
        price_final: price_final,
        margin: margin,
        mult: mult,
        soma: soma,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async updateKKIntervals(id, price_ini, price_final, margin, mult, soma) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/intervals`, {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        price_ini: price_ini,
        price_final: price_final,
        margin: margin,
        mult: mult,
        soma: soma,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async deleteKKIntervals(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/intervals`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  //ORDERS KK
  async acceptOrderKK(id) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/orders/accept`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: id,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  async cancelOrderKK(id, reason) {
    const token = await getToken();
    const response = await fetch(`${API_IDIVIA}kkApi/orders/cancel`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: id,
        reason: reason,
      }),
    });
    if (response.status == 200) return response.json();
    else if (response.status == 403) {
      let error = await response.json();
      if (
        error.message ==
        'The token as failed the verification, please Logout and Login again!'
      )
        await cleanOldLogin();
      console.log(error);
      throw Error(error.message);
    } else {
      let error = await response.json();
      console.log(error);
      throw Error(error.message);
    }
  },
  // Helper function to convert JSON to CSV
  convertToCSV(data) {
    if (!data || !data.length) {
      return '';
    }

    // Get the headers from the keys of the first object in the array
    const headers = Object.keys(data[0]);
    const rows = data.map(
      (row) => headers.map((header) => `"${row[header] || ''}"`).join(',') // Quote and join values
    );

    // Join headers and rows
    return [headers.join(','), ...rows].join('\n');
  },
};

export default idiviaService;
