import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // beforeEnter(to, from, next) {
    //   let user = sessionStorage.getItem('token');
    //   console.log(user);
    //   if (user == null || user.length == 0 || user == undefined) {
    //     next('/login');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/assuntosPendentes',
    name: 'Assuntos Pendentes',
    component: () => import('../views/PendingSubjects.vue'),
  },
  {
    path: '/outrasSettings',
    name: 'Outras Settings',
    component: () => import('../views/OutrasSettings.vue'),
  },
  {
    path: '/feedSettings',
    name: 'Feed Settings',
    component: () => import('../views/FeedSettings.vue'),
  },
  {
    path: '/novaEngelSettings',
    name: 'Nova Engel Settings',
    component: () => import('../views/NovaEngelSettings.vue'),
  },
  {
    path: '/stockSettings',
    name: 'Stock Settings',
    component: () => import('../views/StockSettings.vue'),
  },
  {
    path: '/shippingSettings',
    name: 'Shipping Settings',
    component: () => import('../views/ShippingSettings.vue'),
  },
  {
    path: '/autoPriceSettings',
    name: 'Auto-Price Settings',
    component: () => import('../views/AutoPriceSettings.vue'),
  },
  {
    path: '/salesReportCountry',
    name: 'Sales Report Country',
    component: () => import('../views/SalesReportCountry.vue'),
  },
  {
    path: '/logistica',
    name: 'Logística',
    component: () => import('../views/Logistica.vue'),
  },
  {
    path: '/asneiras',
    name: 'Asneiras',
    component: () => import('../views/Asneiras.vue'),
  },
  {
    path: '/administradores',
    name: 'Administradores',
    component: () => import('../views/Admin.vue'),
  },
  {
    path: '/compras',
    name: 'Compras',
    component: () => import('../views/Compras.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Logs.vue'),
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Orders_New.vue'),
  },
  {
    path: '/suppliers',
    name: 'Fornecedores',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SuppliersList.vue'),
  },
  {
    path: '/brands',
    name: 'Marcas',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Brands.vue'),
  },
  {
    path: '/countries',
    name: 'Países',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Countries.vue'),
  },
  {
    path: '/reportSales',
    name: 'SalesReport',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SalesReport.vue'),
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: () => import('../views/ProductPage'),
  },
  {
    path: '/orders/:id',
    name: 'OrderPage',
    component: () => import('../views/OrderPage'),
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: () => import('../views/TrackingNotDel'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/AllUsers'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile'),
  },
  {
    path: '/newOrder/:orderId',
    name: 'NewOrder',
    component: () => import('../views/CreateNewOrder'),
  },
  {
    path: '/editOrder/:orderId',
    name: 'EditOrder',
    component: () => import('../views/EditOrder'),
  },
  {
    path: '/manualOrder',
    name: 'ManualOrder',
    component: () => import('../views/ManualOrders'),
  },
  {
    path: '/addProduct',
    name: 'AddProduct',
    component: () => import('../views/AddProducts'),
  },
  {
    path: '/purchases/trans',
    name: 'PurchaseTransactions',
    component: () => import('../views/PurchaseTransactions'),
  },
  {
    path: '/farma/orders',
    name: 'FarmaOrders',
    component: () => import('../views/FarmaOrders'),
  },
  {
    path: '/receptionProducts/add',
    name: 'ReceptionProducts',
    component: () => import('../views/ReceptionProducts'),
  },
  {
    path: '/receptionProducts',
    name: 'ListReceptionProducts',
    component: () => import('../views/ListReceptionProducts'),
  },
  {
    path: '/receptionProducts/:id/update',
    name: 'ReceptionProductsUpdate',
    component: () => import('../views/ReceptionProductsUpdate'),
  },
  {
    path: '/ctt/close',
    name: 'CloseCTT',
    component: () => import('../views/CloseCTT.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/NewSettings.vue'),
  },
  {
    path: '/ctt/closeN',
    name: 'CloseCTTN',
    component: () => import('../views/CloseCTTNormal.vue'),
  },
  {
    path: '/receptionsProducts/:id/updateData',
    name: 'UpdateReceptions',
    component: () => import('../views/UpdateReception'),
  },
  {
    path: '/deliveredDays',
    name: 'CountingDays',
    component: () => import('../views/CountingDays'),
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import('../views/Marketing.vue'),
  },
  {
    path: '/marketing/evaluation',
    name: 'MarketingEvaluation',
    component: () => import('../views/MarketingEvaluation.vue'),
  },
  {
    path: '/products/validity',
    name: 'ValidityReport',
    component: () => import('../views/ValidityReport.vue'),
  },
  {
    path: '/products/names',
    name: 'Names Report',
    component: () => import('../views/NamesReport.vue'),
  },
  {
    path: '/products/brands/report',
    name: 'ValidityReport',
    component: () => import('../views/BrandReport.vue'),
  },
  {
    path: '/products/manualPrice/report',
    name: 'ValidityReport',
    component: () => import('../views/PCustoManualReport.vue'),
  },
  {
    path: '/products/aerosol/report',
    name: 'AerosolReport',
    component: () => import('../views/AerosolReport.vue'),
  },
  //Discounts
  {
    path: '/discounts',
    name: 'Descontos',
    component: () => import('../views/Discounts.vue'),
  },
  // Client Platform
  {
    path: '/specialRequests',
    name: 'Client',
    component: () => import('../views/ClientPlatform.vue'),
  },
  {
    path: '/client/allRequests',
    name: 'Client',
    component: () => import('../views/ClientRequest.vue'),
  },
  //Clints Info
  //Client Orders
  {
    path: '/clients',
    name: 'Clientes',
    component: () => import('../views/Clients.vue'),
  },
  {
    path: '/clients/:id',
    name: 'Clientes',
    component: () => import('../views/ClientInfo.vue'),
  },
  {
    path: '/specialRequests/products',
    name: 'Products Clients',
    component: () => import('../views/ClientProducts.vue'),
  },
  {
    path: '/specialRequests/products/:sku',
    name: 'Products Clients',
    component: () => import('../views/ClientProductPage.vue'),
  },
  {
    path: '/client/createOrder',
    name: 'Products Clients',
    component: () => import('../views/ClientCreateOrder.vue'),
  },
  {
    path: '/specialRequests/orders',
    name: 'Clients Orders',
    component: () => import('../views/ClientOrders.vue'),
  },
  {
    path: '/specialRequests/orders/:id',
    name: 'Client Order',
    component: () => import('../views/ClientOrder.vue'),
  },
  {
    path: '/client/productsPrice',
    name: 'Client Products Price',
    component: () => import('../views/ProductsClientPrices.vue'),
  },
  {
    path: '/client/info',
    name: 'Clients Orders Info',
    component: () => import('../views/ClientRequestsHome.vue'),
  },
  {
    path: '/brandsPurchase',
    name: 'Client Order',
    component: () => import('../views/BrandsPurchase.vue'),
  },
  {
    path: '/labs',
    name: 'Labs',
    component: () => import('../views/Labs.vue'),
  },
  {
    path: '/labs/admin',
    name: 'LabsAdmin',
    component: () => import('../views/AdminBrandPurchase.vue'),
  },
  {
    path: '/insurance',
    name: 'Insurance',
    component: () => import('../views/Insurance.vue'),
  },
  {
    path: '/importFiles',
    name: 'ImportFiles',
    component: () => import('../views/ImportFiles.vue'),
  },
  {
    path: '/feedsCountry',
    name: 'FeedsCountry',
    component: () => import('../views/FeedsCountry.vue'),
  },
  {
    path: '/reports',
    name: 'Relatórios',
    component: () => import('../views/Reports.vue'),
  },
  {
    path: '/cttEmail',
    name: 'Ctt Email',
    component: () => import('../views/CttEmail.vue'),
  },
  {
    path: '/ctt/deliveryNotes',
    name: 'DeliveryNotes',
    component: () => import('../views/CttDeliveryNotes.vue'),
  },
  //Accounting
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import('../views/Accounting.vue'),
  },
  {
    path: '/accounting/supplier/:id',
    name: 'AccountingBySupplier',
    component: () => import('../views/AccountingBySupplier.vue'),
  },
  //Shipping Reports
  {
    path: '/shippingReports',
    name: 'ShippingReports',
    component: () => import('../views/ShippingReport.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to);
  const auth = localStorage.getItem('token');

  if (to.name !== 'Login' && !auth)
    next({
      name: 'Login',
    });
  else next();
});

export default router;
