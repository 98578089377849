<template>
  <div class="container">
    <h2>{{ this.loggedUser.username }}</h2>
    <h4>{{ dayWeek }}, {{ day }} {{ month }} {{ year }}</h4>

    <!-- DASHBOARD INFO NO TOPO COM MINIMIZAÇÃO -->
    <div class="dashboard" v-if="role != 'farmas' && role != 'client'">
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
        >
          <h3>Dashboard</h3>
          <button @click="toggleDashboard" class="btn btn-sm btn-primary">
            {{ isDashboardVisible ? 'Minimizar' : 'Expandir' }}
          </button>
        </div>
        <div v-if="isDashboardVisible" class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Encomendas Pendentes</h5>
                  <p>{{ dashboardInfo.pendent_orders }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Encomendas há mais de 3 dias</h5>
                  <p>{{ dashboardInfo.orders_more_than_3_days }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Encomendas c/tracking duplicado</h5>
                  <p>{{ dashboardInfo.orders_duplicate_tracking }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Encomendas c/captura em falta</h5>
                  <p>{{ dashboardInfo.orders_without_capture }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    Encomendas completas c/captura em falta
                  </h5>
                  <p>{{ dashboardInfo.orders_completed_without_capture }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Receções sem fatura</h5>
                  <p>{{ dashboardInfo.invoices_without_bill }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Produtos a validar</h5>
                  <p>{{ dashboardInfo.products_to_verify }}</p>
                </div>
              </div>
            </div>
            <div
              class="col-md-4"
              v-for="ctt in dashboardInfo.ctt_remaining_range"
              :key="ctt.code"
            >
              <div class="card" :class="{ 'alert-card': ctt.available < 300 }">
                <div class="card-body">
                  <h5 class="card-title">CTT Código: {{ ctt.code }}</h5>
                  <p :style="{ color: ctt.available < 300 ? 'red' : 'black' }">
                    Disponível: {{ ctt.available }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="dashboard">
        <div class="card-container">
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/beauty-products-1603140461.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Produtos</h5>
              <p class="card-text">Ver todos os Produtos</p>
              <a href="/about" class="btn btn-primary">Ver Produtos</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://www.apol.pt/wp-content/uploads/2020/12/apol-subcontracao-logistica.png"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Logística</h5>
              <p class="card-text">
                Ver encomendas, receber produtos e adicionar encomendas Manuais
              </p>
              <a href="/logistica" class="btn btn-primary">Logística</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://maestrovirtuale.com/wp-content/uploads/2019/10/Departamento-de-Compras1.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Compras</h5>
              <p class="card-text">Ver produtos em falta</p>
              <a href="/compras" class="btn btn-primary">Comprar</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://www.leak.pt/wp-content/uploads/2023/06/compras-online-scaled-e1686992444768.jpeg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Lista de Compras a Laboratórios</h5>
              <p class="card-text">
                Ver os pedidos de compras aos laboratórios e seus estados
              </p>
              <a href="/labs/admin" class="btn btn-primary">Ver Pedidos</a>
            </div>
          </div>
          <div
            v-if="role == 'admin' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/beauty-products-1603140461.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Administrador</h5>
              <p class="card-text">Ver movimentos, ver utilizadores</p>
              <a href="/administradores" class="btn btn-primary">Admin</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://i0.wp.com/contaonline.pt/wp-content/uploads/2018/03/contabilidade-para-que-serve-contaonline.jpg?resize=1024%2C603&ssl=1"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Contabilidade</h5>
              <p class="card-text">Ver contabilidade da empresa</p>
              <a href="/accounting" class="btn btn-primary">Ver mais</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://www.marcom-connect.com/wp-content/uploads/2012/08/testimonials-clients-marcom11.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Clientes</h5>
              <p class="card-text">Ver todos os clientes</p>
              <a href="/clients" class="btn btn-primary">Ver Clientes</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://www.shipbob.com/wp-content/uploads/2019/01/iStock-692898468-2-optimized.webp"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Informação de Pedidos de Cliente</h5>
              <p class="card-text">
                Todo relazionado a pedidos especiais dos Clientes
              </p>
              <a href="/client/info" class="btn btn-primary">Ver Pedidos</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://infoportugal.pt/wp-content/uploads/2019/10/Tracking__by_InfoPortugal.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Tracking</h5>
              <p class="card-text">Gerir encomendas não entregues</p>
              <a href="/tracking" class="btn btn-primary">Ver</a>
              <a href="/carina" class="btn btn-primary">Ver Emails Agendados</a>
            </div>
          </div>
          <div v-if="role == 'daniela'" class="card">
            <img
              src="https://www.ksnlaw.com/blog/wp-content/uploads/2018/04/49-600x325.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Compras ao Laboratório</h5>
              <p class="card-text">
                Lista de Produtos a pedir aos Laboratórios
              </p>
              <a href="/brandsPurchase" class="btn btn-primary">Abrir</a>
            </div>
          </div>

          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://img.freepik.com/vetores-premium/entrega-de-seguros-e-frete-de-carga_212005-235.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Seguros</h5>
              <p class="card-text">Adicionar e visualizar seguros recebidos</p>
              <a href="/insurance" class="btn btn-primary">Ver encomendas</a>
            </div>
          </div>
          <div v-if="role == 'farmas'" class="card">
            <img
              src="https://www.cm-vouzela.pt/wp-content/uploads/2021/07/farmacias-1.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Farmácias</h5>
              <p class="card-text">Ver encomendas Farmácias</p>
              <a href="/farma/orders" class="btn btn-primary">Ver encomendas</a>
            </div>
          </div>
          <div v-if="role == 'logistica' || role == 'admin'" class="card">
            <img
              src="https://sunshineteacherstraining.id/wp-content/uploads/2020/08/Mistake1.png.webp"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Registo de Asneiras</h5>
              <p class="card-text">Registar nova asneira</p>
              <a href="/asneiras" class="btn btn-primary">Registo</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5BEhjEDgHh1p5KfkAg9HyovOuu8BNB_Tlpw&s"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Assuntos Pendentes</h5>
              <p class="card-text">Gerir Assuntos Pendentes</p>
              <a href="/assuntosPendentes" class="btn btn-primary">Abrir</a>
            </div>
          </div>
          <div
            v-if="role != 'farmas' && role != 'client' && role != 'daniela'"
            class="card"
          >
            <img
              src="https://blogdorei.com/wp-content/uploads/13-Ideias-de-Marketing-para-saloes.png"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Marketing</h5>
              <p class="card-text">Gestão do Marketing</p>
              <a href="/marketing" class="btn btn-primary">Marketing</a>
            </div>
          </div>
          <div v-if="role == 'client'" class="card">
            <img
              src="https://www.shipbob.com/wp-content/uploads/2019/01/iStock-692898468-2-optimized.webp"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Create Order Request</h5>
              <p class="card-text">Make a Order Request</p>
              <a href="/specialRequests" class="btn btn-primary">Open</a>
            </div>
          </div>
          <div v-if="role == 'client'" class="card">
            <img
              src="https://www.ksnlaw.com/blog/wp-content/uploads/2018/04/49-600x325.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Our Orders</h5>
              <p class="card-text">See our orders</p>
              <a href="/specialRequests/orders" class="btn btn-primary">Open</a>
            </div>
          </div>

          <div v-if="role == 'client'" class="card">
            <img
              src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/beauty-products-1603140461.jpg"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Products</h5>
              <p class="card-text">List of all my Products</p>
              <a href="/specialRequests/products" class="btn btn-primary"
                >See Products</a
              >
            </div>
          </div>
          <div v-if="role == 'daniela'" class="card">
            <img
              src="https://www.yesstyle.com/blog/wp-content/uploads/2023/01/skincare_featured.webp"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Marcas</h5>
              <p class="card-text">Lista de Todas as Marcas</p>
              <a href="/brands" class="btn btn-primary">Ver Marcas</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      loggedUser: [],
      isDashboardVisible: false, // Controla a visibilidade da dashboard
      totalProcessing: 0,
      totalPicking: 0,
      percProcessing: 0,
      dayWeek: '',
      day: '',
      month: '',
      year: '',
      role: '',
      allMonths: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outobro',
        'Novembro',
        'Dezembro',
      ],
    };
  },
  methods: {
    toggleDashboard() {
      this.isDashboardVisible = !this.isDashboardVisible; // Alterna a visibilidade da dashboard
    },
    async getUserInformation() {
      let token = localStorage.getItem('token');
      let user = JSON.parse(localStorage.getItem('user'));
      this.role = user.role;
      console.log('ROLEEEE', this.role);
      let jwt = token.split('.')[1];
      this.loggedUser = JSON.parse(window.atob(jwt));
      console.log(this.loggedUser.username);
    },
    getDay() {
      let date = new Date();
      let weekDay = date.getDay();
      this.day = date.getDate();
      let mon = date.getMonth();
      this.year = date.getFullYear();
      switch (weekDay) {
        case 1:
          this.dayWeek = 'Segunda-Feira';
          break;
        case 2:
          this.dayWeek = 'Terça-Feira';
          break;
        case 3:
          this.dayWeek = 'Quarta-Feira';
          break;
        case 4:
          this.dayWeek = 'Quinta-Feira';
          break;
        case 5:
          this.dayWeek = 'Sexta-Feira';
          break;
        case 6:
          this.dayWeek = 'Sábado';
          break;
        case 7:
          this.dayWeek = 'Domingo';
          break;
      }
      this.month = this.allMonths[mon];
    },
    async getDashboardInfoF() {
      try {
        await this.$store.dispatch('getDashboardInfo');
        this.dashboardInfo = this.getDashboardInfo;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getUserInformation();
    await this.getDay();
    await this.getDashboardInfoF();
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
    ...mapGetters(['getDashboardInfo']),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

h2 {
  margin: 50px 50px 0px 0px;
}
h4 {
  margin: 0px 50px 50px 0px;
}

.btn {
  margin-top: 10px;
  float: right;
  background: rgb(255, 147, 147);
  border: none;
  box-shadow: none !important;
}
.btn:hover,
.btn:focus {
  background: rgba(255, 147, 147, 0.658);
  border: white;
}

.card {
  margin-top: 10px;
  border-radius: 10px;
}

img {
  height: 80px;
  object-fit: cover;
  object-position: 0% 50;
}

.dashboard {
  padding: 10px;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.container {
  max-width: 90%;
}

.alert-card {
  border: 2px solid red;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  body {
    padding: 10px;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}
</style>
